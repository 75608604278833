import type { FC } from 'react'
import { memo, useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Select } from 'common/components/Select/Select'

import { Skeleton } from 'antd'
import { format, isAfter } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import { ReactComponent as NavigationIcon } from 'assets/icons/navigation-arrow.svg'

import { Show } from 'common/components/Show/Show'
import { DATE_FORMAT } from 'common/constants/dateFormatConstants'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { DateService } from 'common/services/dateService'

import {
  ALLOW_RESCHEDULE_CURRENT_DAY_PAST_EVENTS,
  INFO_CONSTANTS,
  PATIENT_SCHEDULE_SCROLL_HEIGHT_OFFSET,
  PATIENT_STATUSES,
} from 'features/Home/constants/infoConstants'
import { useInfoManager } from 'features/Home/hooks/useInfoManager'
import { useInfoPusher } from 'features/Home/hooks/useInfoPusher'
import type { IPatientRoom } from 'features/Home/interfaces/IInfoPatient'
import type { IPatientSchedule } from 'features/Home/interfaces/IInfoSchedule'
import { InfoService } from 'features/Home/services/infoService'
import { CLEANUP_INFO_STATE, UPDATE_INFO_DRAGGING } from 'features/Home/Book/state/slice/bookSlice'

import styles from './patientTracker.module.scss'
import type { PatientTrackerProps } from './patientTracker.types'
import { DocumentResourceHeader } from 'features/Home/Book/Booking/Schedule/PatientSchedule/DocumentResourceHeader/DocumentResourceHeader'
import { EmptyRoomsList } from 'features/Home/Book/Booking/Schedule/PatientSchedule/EmptyRoomsList/EmptyRoomsList'
import { AddWalkInPatientButton } from 'features/Home/Book/Booking/Schedule/PatientSchedule/AddWalkInPatientButton/AddWalkInPatientButton'
import { Calendar } from 'features/Home/Book/Booking/Schedule/PatientSchedule/Calendar/Calendar'
import { PatientScheduleSkeleton } from 'features/Home/Book/Booking/Schedule/PatientSchedule/PatientScheduleSkeleton/PatientScheduleSkeleton'
import { PatientSelection } from './PatientSelection/PatientSelection'
import { AddEditPatient } from 'features/Home/Book/Booking/Schedule/PatientSchedule/AddEditPatient/AddEditPatient'
import { PATH_SEGMENT } from 'routes/pathSegments'
import { PathUtils } from 'routes/routes.utils'
import { SELECT_CONSTANTS } from 'common/constants/selectConstants'
import { PATIENT_ACTIVITY_INTERVAL } from 'features/Home/constants/infoActivityTime'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { ScheduleCalendar } from 'features/Home/Book/Booking/Schedule/PatientSchedule/ScheduleCalendar/ScheduleCalendar'

dayjs.extend(utc)
dayjs.extend(timezone)

export const PatientTracker: FC<PatientTrackerProps> = memo(() => {
  const [addPatient, setAddPatient] = useState<boolean>(false)
  const [editedPatient, setEditedPatient] = useState<IPatientSchedule | null>(null)
  const [hasFinishedFirstLoad, setHasFinishedFirstLoad] = useState<boolean>(false)
  const [prePopulateSchedule, setPrePopulateSchedule] = useState<number | null>(
    PATIENT_ACTIVITY_INTERVAL[0].code as number,
  )
  const [customAppointmentData, setCustomAppointmentData] = useState<{
    appointment: Date[]
    room: string
  } | null>(null)

  useInfoPusher()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { siteId, bookingId } = useParams()

  const { rooms, room_statuses, schedules, isFetching, timeZone, doctorsBufferTime } =
    useAppSelector((state) => state.bookReducer)

  const today = useMemo(
    () => format(utcToZonedTime(new Date(), timeZone), DATE_FORMAT.CALENDAR_DATE),
    [timeZone],
  )

  const { fetchInfoData, updateInfoRoomStatus } = useInfoManager(today)

  const isPromotion = useMemo(
    () => location.pathname.includes(PATH_SEGMENT.PROMOTION),
    [location.pathname],
  )

  const areAvailableSlots = useMemo(() => {
    if (!rooms) {
      return false
    }

    return rooms.length && rooms.some((room: IPatientRoom) => room.slots.length > 0)
  }, [rooms])

  const areSomeRoomsGenerated = useMemo(() => {
    if (!rooms) {
      return false
    }

    return rooms.length && rooms.some((room) => !!room.id)
  }, [rooms])

  const areAllRoomsGenerated = useMemo(() => {
    if (!rooms) {
      return false
    }

    return rooms.length && rooms.every((room) => !!room.id)
  }, [rooms])

  const updateStatus = (value: number, room: IPatientRoom) => {
    updateInfoRoomStatus(value, room)
  }

  const getWaitingRoomPatients = () => {
    try {
      const waitingRoom = rooms.find((room: IPatientRoom) => {
        return room.is_waiting_room
      })
      if (waitingRoom) {
        const waitingRoomCode = waitingRoom.value
        const checkedPatientsInWaitingRoom = schedules.filter((schedules: IPatientSchedule) => {
          return (
            schedules.room.value === waitingRoomCode &&
            schedules.status.code === PATIENT_STATUSES.CHECKED_IN_ON_SITE
          )
        })
        return checkedPatientsInWaitingRoom.length
      }
      return null
    } catch (error) {
      return null
    }
  }

  const roomsHeader = useMemo(
    () =>
      rooms?.map((room: IPatientRoom) => {
        return {
          resourceId: `${room.label}/${room.value}`,
          resourceTemplate: (
            <DocumentResourceHeader
              doctorsBufferTime={doctorsBufferTime}
              room={room}
              hasRoomStatus={!room.is_waiting_room && !!room.add_patient}
              statusList={room_statuses}
              updateStatus={updateStatus}
              timeZone={timeZone}
              key={room.id}
              {...(room.is_waiting_room && { patientsCount: getWaitingRoomPatients() })}
            />
          ),
        }
      }),
    [rooms, schedules],
  )

  const handleDisplayEventDetails = (data: any): void => {
    const clickedPatient = schedules.find((schedule: IPatientSchedule) => schedule.id === data.id)

    if (clickedPatient) {
      const sortedSlots = InfoService.getSortedSlots(clickedPatient.room.slots, today)

      const endOfTheDay = sortedSlots.at(-1).end_time

      if (isAfter(data.start, endOfTheDay)) {
        return
      }
    }

    setEditedPatient(clickedPatient)
  }

  const isDisabledWalkinPatientButton = useMemo(() => {
    if (!rooms) {
      return true
    }
    const roomsSlots = rooms.map((room: IPatientRoom) => room.slots).flat()

    const sortedSlots = InfoService.getSortedSlots(roomsSlots, today)

    const endOfTheDay = sortedSlots.at(-1)?.end_time
    if (!endOfTheDay) return true

    const currentTime = dayjs().tz(timeZone)
    const endTime = dayjs.tz(dayjs(endOfTheDay).format('YYYY-MM-DD HH:mm:ss'), timeZone)
    return currentTime.isAfter(endTime)
  }, [rooms, timeZone, today])

  const handleCloseModal = () => {
    setAddPatient(false)
    setEditedPatient(null)
    setCustomAppointmentData(null)
    dispatch(UPDATE_INFO_DRAGGING(false))
  }

  const createScheduleWithCustomAppointment = (
    start: Date,
    end: Date,
    resourceId: string,
  ): void => {
    setCustomAppointmentData({ appointment: [start, end], room: resourceId })
    setAddPatient(true)
  }

  useEffect(() => {
    if (!today) {
      return
    }

    fetchInfoData().then(() => {
      setHasFinishedFirstLoad(true)
    })
  }, [today])

  useEffect(() => {
    if (editedPatient) {
      handleDisplayEventDetails(editedPatient)
    }
  }, [schedules, editedPatient])

  useEffect(() => {
    const handleScroll = () => {
      const headerAll = document.querySelectorAll('.rbc-time-header')
      if (window.scrollY > PATIENT_SCHEDULE_SCROLL_HEIGHT_OFFSET && headerAll.length > 0) {
        Array.from(headerAll).map((header) => header.classList.add(styles.parentSticky))
      } else {
        Array.from(headerAll).map((header) => header.classList.remove(styles.parentSticky))
      }
    }
    document.addEventListener('scroll', handleScroll)

    return () => {
      dispatch(CLEANUP_INFO_STATE())
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const Activity = useMemo(() => {
    return (
      <>
        <Show when={isPromotion && !areAllRoomsGenerated}>
          <EmptyRoomsList
            title='You need to select your time slots for today before you can add any patients'
            content={
              <Link
                className={styles.parentEmptyLink}
                to={PathUtils.getPromotionDetails(siteId, bookingId)}>
                Book now <NavigationIcon width={32} height={32} />
              </Link>
            }
          />
        </Show>
        <Show
          when={areAvailableSlots && areSomeRoomsGenerated && hasFinishedFirstLoad}
          fallback={
            <Show when={areSomeRoomsGenerated && !areAllRoomsGenerated}>
              <EmptyRoomsList
                title={
                  areSomeRoomsGenerated
                    ? INFO_CONSTANTS.NO_ACTIVE_ROOMS_TODAY
                    : INFO_CONSTANTS.NO_ACTIVE_LEASES_TODAY
                }
              />
            </Show>
          }>
          <div className={styles.parentActionContainer}>
            <AddWalkInPatientButton
              isDisabled={isDisabledWalkinPatientButton}
              handleClick={() => setAddPatient(true)}
              label={INFO_CONSTANTS.ADD_WALK_IN_PATIENT}
            />
            <div className={styles.parentHeadContentSelect}>
              <span className={styles.parentHeadInfo}>{INFO_CONSTANTS.PRE_POPULATE_USING}</span>
              <div className={styles.parentHeadSelect}>
                <Select
                  onSelect={setPrePopulateSchedule}
                  popupClassName='table-select-content'
                  proportion={SELECT_CONSTANTS.SMALL}
                  listOptions={PATIENT_ACTIVITY_INTERVAL}
                  value={prePopulateSchedule}
                />
              </div>
              <span className={styles.parentHeadInfo}>{INFO_CONSTANTS.TIME_SLOTS}</span>
            </div>
          </div>
          <Calendar
            isPromotion={isPromotion}
            roomsHeader={roomsHeader}
            handleDisplayEventDetails={handleDisplayEventDetails}
            isRealTimeTracker
            createCustomAppointment={createScheduleWithCustomAppointment}
            prepopulateTimeOnClick={prePopulateSchedule}
          />
        </Show>
      </>
    )
  }, [
    siteId,
    bookingId,
    isPromotion,
    roomsHeader,
    areAvailableSlots,
    areSomeRoomsGenerated,
    areAllRoomsGenerated,
    hasFinishedFirstLoad,
    isDisabledWalkinPatientButton,
    prePopulateSchedule,
  ])

  return (
    <div>
      <ScheduleCalendar
        resources={roomsHeader}
        setEditedPatient={setEditedPatient}
        prepopulateTimeOnClick={prePopulateSchedule}
        createCustomAppointment={createScheduleWithCustomAppointment}
      />

      <Show
        when={!isFetching || hasFinishedFirstLoad}
        fallback={
          <>
            <Skeleton.Node className={styles.skeletonButton} />
            <PatientScheduleSkeleton />
          </>
        }>
        {Activity}
      </Show>
      <PatientSelection />
      <AddEditPatient
        date={today}
        open={addPatient || !!editedPatient}
        isEditing={!!editedPatient}
        editedPatientData={editedPatient}
        setOpen={handleCloseModal}
        prePopulateSchedule={prePopulateSchedule}
        disabledEverything={
          editedPatient
            ? DateService.isPast(new Date(`${today} ${editedPatient?.start_time}`), timeZone) &&
              !ALLOW_RESCHEDULE_CURRENT_DAY_PAST_EVENTS.includes(editedPatient?.status.code)
            : false
        }
        title={
          addPatient
            ? INFO_CONSTANTS.ADD_WALK_IN_PATIENT
            : !!editedPatient
            ? INFO_CONSTANTS.EDIT_WALK_IN_PATIENT
            : null
        }
        customAppointment={customAppointmentData}
      />
    </div>
  )
})
