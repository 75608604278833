import { addMinutes, differenceInMinutes, format } from 'date-fns'
import {
  COUNT_AS_OVERLAPPING,
  MIN_SCHEDULE_TIME_IN_MINUTES,
  ROOM_STATUS,
} from 'features/Home/constants/infoConstants'
import { ALERT_CONSTANTS } from 'common/constants/alertConstants'
import type { IPatientRoom } from 'features/Home/interfaces/IInfoPatient'
import { TIME_FORMAT } from 'common/constants/timeFormat'
import { UPDATE_INFO_DRAGGING } from 'features/Home/Book/state/slice/bookSlice'
import { useNotification } from 'app/providers'
import { InfoService } from 'features/Home/services/infoService'
import { UtilService } from 'common/services/utilService'
import type { IPatientSchedule } from 'features/Home/interfaces/IInfoSchedule'
import { useEffect, useMemo, useState } from 'react'
import { useAppDispatch } from 'common/hooks/redux'
import { useInfoManager } from 'features/Home/hooks/useInfoManager'

type UseScheduleCalendarResponse = {
  handleResize: (data: any) => Promise<void>
  handleDrag: (props: any) => Promise<void>
  events: IPatientSchedule[]
}

type UseScheduleCalendarProps = {
  dayInterval: { min: string; max: string }
  disabledEverything?: boolean
  disabledIntervals: any[]
  formattedDate: string
  isUpdating: boolean
  rooms: IPatientRoom[]
  schedules: IPatientSchedule[]
  timeZone: string
}

export const useScheduleCalendar = ({
  dayInterval,
  disabledEverything = false,
  disabledIntervals,
  formattedDate,
  isUpdating,
  rooms,
  schedules,
  timeZone,
}: UseScheduleCalendarProps): UseScheduleCalendarResponse => {
  const [events, setEvents] = useState<IPatientSchedule[]>(schedules)
  const [awaitConfirmationEvent, setAwaitConfirmationEvent] = useState<any>(null)

  const dispatch = useAppDispatch()

  const { setNotification } = useNotification()
  const { updatePatientSchedule } = useInfoManager(formattedDate)

  const isBetween = (number: number, lowerBound: number, upperBound: number): boolean => {
    return number >= lowerBound && number <= upperBound
  }

  const updateDragging = (value: boolean) => {
    dispatch(UPDATE_INFO_DRAGGING(value))
  }

  const handleUpdateEvents = async (adjustedData: any, event: any): Promise<boolean> => {
    return await updatePatientSchedule(adjustedData, event.id)
  }

  const shouldProceedToUpdate = ({
    resourceId,
    start,
    end,
    event,
    room,
    isResizing,
  }: {
    resourceId: string
    start: any
    end: any
    event: any
    room: IPatientRoom
    isResizing?: boolean
    isDrag?: boolean
  }) => {
    const diffInMinutes = differenceInMinutes(end, start)

    if (diffInMinutes < MIN_SCHEDULE_TIME_IN_MINUTES) {
      setNotification({
        title: 'Update schedule error',
        description: `You're schedule can't be less than ${MIN_SCHEDULE_TIME_IN_MINUTES} minutes.`,
        type: ALERT_CONSTANTS.ERROR,
      })

      return false
    }

    const eventsFromRoom = events.filter(
      (item) =>
        item.room_id === resourceId &&
        item.id !== event.id &&
        COUNT_AS_OVERLAPPING.includes(item.status.code),
    )

    const { nrOfOverlappingEvents, overlappingEvents } =
      InfoService.getNumberOfOverlappingIntervals({ start, end }, eventsFromRoom, resourceId)

    if (!isBetween(nrOfOverlappingEvents, 1, room.max_patients || 1) && !room?.is_waiting_room) {
      setNotification({
        title: 'Update schedule error',
        description: `You reached the max number of ${
          room.max_patients || 1
        } patients at the same time for this room.`,
        type: ALERT_CONSTANTS.ERROR,
      })
      return false
    }

    const shouldApplyRoomRule = !InfoService.checkIfCurrentDateOverlapDisabledIntervals(
      disabledIntervals,
      room,
      dayInterval,
      timeZone,
    )
    if (
      shouldApplyRoomRule &&
      room &&
      room.status &&
      room.status.code !== ROOM_STATUS.FREE &&
      room.status.code !== ROOM_STATUS.CLEANUP &&
      room.status.code !== ROOM_STATUS.BUSY &&
      !isResizing
    ) {
      return false
    }

    const { start_time: event_start, end_time: event_end, room_id: eventRoom } = event
    const { overlappingEvents: overlappingEventsBeforeUpdate } =
      InfoService.getNumberOfOverlappingIntervals(
        { start: event_start, end: event_end },
        eventsFromRoom,
        eventRoom,
      )

    const shouldShowOverlappingEventsNotification =
      nrOfOverlappingEvents > 1 &&
      !UtilService.arrDeepEqual(overlappingEventsBeforeUpdate, overlappingEvents) &&
      room.value !== rooms[rooms.length - 1].value

    if (shouldShowOverlappingEventsNotification) {
      setAwaitConfirmationEvent({
        start,
        end,
        event,
        room,
      })

      return false
    }

    return true
  }

  const handleResize = async (data: IPatientSchedule): Promise<void> => {
    const startTime = new Date(data.start_time)
    let adjustedEnd = new Date(data.end_time)
    let diffInMinutes = differenceInMinutes(adjustedEnd, startTime)

    if (diffInMinutes === 0) {
      adjustedEnd = addMinutes(startTime, 5)
    }

    const [label, value] = data.room_id.split('/')
    const room = rooms.find((room: IPatientRoom) => room.label === label && room.value === value)
    const shouldUpdateEvent = shouldProceedToUpdate({
      resourceId: data.room_id,
      start: startTime,
      end: adjustedEnd,
      event: data,
      room,
      isResizing: true,
    })

    if (!shouldUpdateEvent) {
      return
    }

    const adjustedData = {
      room: room?.value,
      start_time: format(startTime, TIME_FORMAT.TIME_PICKER_HH_SS),
      end_time: format(adjustedEnd, TIME_FORMAT.TIME_PICKER_HH_SS),
      temperature_bypass: data.temperature_bypass,
      consultation_staff: data.consultation_staff,
      procedure_code: !data?.procedure?.isCustom ? data?.procedure?.code : null,
      procedure_description: data?.procedure?.description,
    }

    const copyOfEvents = [...events]
    const updated = events.map((item) =>
      item.id === data.id ? { ...item, end: adjustedEnd, start_time: startTime } : item,
    )
    setEvents(updated)

    const successUpdate = await handleUpdateEvents(adjustedData, data)
    if (!successUpdate) {
      setEvents(copyOfEvents)
    } else {
      dispatch(UPDATE_INFO_DRAGGING(false))
    }
  }

  const handleDrag = async (data: IPatientSchedule): Promise<void> => {
    updateDragging(false)

    if (isUpdating || disabledEverything) {
      return
    }

    const start = new Date(data.start_time)
    const end = new Date(data.end_time)

    const diffInMinutes = differenceInMinutes(end, start)
    if (diffInMinutes < MIN_SCHEDULE_TIME_IN_MINUTES) {
      setNotification({
        title: 'Update schedule error',
        description: `Schedule duration can't be less than ${MIN_SCHEDULE_TIME_IN_MINUTES} minutes.`,
        type: ALERT_CONSTANTS.ERROR,
      })
      return
    }

    const [label, value] = data.room_id.split('/')
    const room = rooms.find((room: IPatientRoom) => room.label === label && room.value === value)
    const shouldUpdateEvent = shouldProceedToUpdate({
      resourceId: data.room_id,
      start,
      end,
      event: data,
      room,
      isDrag: true,
    })

    if (!shouldUpdateEvent) {
      return
    }

    const adjustedData = {
      room: room.value,
      consultation_staff: data.consultation_staff,
      start_time: format(start, TIME_FORMAT.TIME_PICKER_HH_SS),
      end_time: format(end, TIME_FORMAT.TIME_PICKER_HH_SS),
      temperature_bypass: data.temperature_bypass,
      procedure_code: !data?.procedure?.isCustom ? data?.procedure?.code : null,
      procedure_description: data?.procedure?.description,
    }

    const copyOfEvents = [...events]
    const updated = events.map((item) => {
      return item.id === data.id ? { ...item, end, start, resourceId: data.room_id } : item
    })

    setEvents(updated)
    const successUpdate = await handleUpdateEvents(adjustedData, data)
    if (!successUpdate) {
      setEvents(copyOfEvents)
    }
  }

  const eventsToShow = useMemo(() => {
    return events.map((event) => {
      return {
        ...event,
        room_id: `${event.room.label}/${event.room.value}`,
        start_time: `${event.date} ${event.start_time}`,
        end_time: `${event.date} ${event.end_time}`,
      }
    })
  }, [events])

  useEffect(() => {
    if (!schedules) {
      return
    }

    setEvents(schedules)
  }, [schedules])

  return { handleResize, handleDrag, events: eventsToShow }
}
