import type { FC } from 'react'
import React, {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar'
import type { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { useLocation, useToggle } from 'react-use'

import classNames from 'classnames'
import {
  add,
  addMinutes,
  compareAsc,
  differenceInMinutes,
  format,
  getDay,
  isAfter,
  parse,
  startOfWeek,
  toDate,
} from 'date-fns'
import enUS from 'date-fns/locale/en-US'

import { useScreenWidth } from 'app/hooks/useScreenWidth'
import { useNotification } from 'app/providers'

import sendMessageIcon from 'assets/icons/send-message.svg'

import { Button } from 'common/components/Button/Button'
import { Show } from 'common/components/Show/Show'
import SpinnerOverlay from 'common/components/SpinnerOverlay/SpinnerOverlay'
import { ALERT_CONSTANTS } from 'common/constants/alertConstants'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { BUTTON_CONSTANTS, BUTTON_MODIFIER } from 'common/constants/buttonConstants'
import { TIME_FORMAT } from 'common/constants/timeFormat'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { DateService } from 'common/services/dateService'
import { UtilService } from 'common/services/utilService'

import {
  ACTION_TYPES,
  ALLOW_RESCHEDULE_CURRENT_DAY_PAST_EVENTS,
  COUNT_AS_OVERLAPPING,
  INFO_CONSTANTS,
  MIN_LONG_PRESS_TO_ADD_SCHEDULE_MS,
  MIN_PRE_POPULATE_TIME,
  MIN_SCHEDULE_TIME_IN_MINUTES,
  PATIENT_STATUSES,
  ROOM_STATUS,
  STATUSES_TO_BE_HIDDEN_IN_PATIENT_ACTIVITY,
} from 'features/Home/constants/infoConstants'
import { useInfoManager } from 'features/Home/hooks/useInfoManager'
import { useMutationObserver } from 'features/Home/hooks/useMutationObserver'
import type { IPatientRoom } from 'features/Home/interfaces/IInfoPatient'
import type { IPatientSchedule } from 'features/Home/interfaces/IInfoSchedule'
import { InfoService } from 'features/Home/services/infoService'
import { UPDATE_INFO_DRAGGING } from 'features/Home/Book/state/slice/bookSlice'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import calendarEvent from './CalendarEvent/CalendarEvent'
import styles from './calendar.module.scss'

import './grid.css'
import type { CalendarProps } from './calendar.types'
import { ZoomControls } from './ZoomControls/ZoomControls'
import { Warning } from './Warning/Warning'
import { SendSMSConfirmation } from './SendSMSConfirmation/SendSMSConfirmation'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { Minimap } from 'common/components/Minimap/Minimap'
import { useDetectMobile } from 'app/hooks/useDetectMobile'

const DndCalendar = withDragAndDrop(BigCalendar)

const defaultView = 'day'
const defaultHeaderWidth = 380
const locales = {
  'en-US': enUS,
}
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

export const Calendar: FC<CalendarProps> = memo(
  ({
    roomsHeader = [],
    message = null,
    subtitle,
    disabledEverything = false,
    isRealTimeTracker = false,
    prepopulateTimeOnClick = MIN_PRE_POPULATE_TIME,
    handleDisplayEventDetails,
    createCustomAppointment,
    isPromotion,
  }) => {
    const [events, setEvents] = useState([])
    const [time, setTime] = useState(new Date())
    const [zoomLevel, setZoomLevel] = useState<number>(5)
    const [columnWidth, setColumnWidth] = useState<number>(defaultHeaderWidth)
    const [timeIndicator, setTimeIndicator] = useState<any>(null)
    const [awaitConfirmationEvent, setAwaitConfirmationEvent] = useState<any>(null)
    const [showBusyNotification, setShowBusyNotification] = useToggle(false)
    const [isSendSmsConfirmVisible, setIsSendSmsConfirmVisible] = useToggle(false)
    const [allowAddSchedule, setAllowAddSchedule] = useState<boolean>(false)
    const [zoomControlsWidth, setZoomControlsWidth] = useState<number>(0)
    const [containerWidth, setContainerWidth] = useState(0)
    const [contentWidth, setContentWidth] = useState(0)
    const [contentScroll, setContentScroll] = useState(0)

    const location = useLocation()
    const dispatch = useAppDispatch()

    const { day } = useParams()
    const formattedDate = dayjs(day).format('YYYY-MM-DD')

    const { setNotification } = useNotification()
    const { screenWidth } = useScreenWidth()

    const isMobileOrTablet = useDetectMobile()
    const { updatePatientSchedule, sendInfoPatientsNotification } = useInfoManager(formattedDate)

    const timerRef = useRef(null)
    const calendarRef = useRef(null)
    const timeIndicatorRef = useRef(null)

    const {
      schedules,
      rooms,
      isFetching,
      isUpdating,
      isSendingNotifications,
      eventsToSendNotification,
      disabledIntervals,
      dayInterval,
      timeZone,
    } = useAppSelector((state) => state.bookReducer)

    const defaultDate = useMemo(() => {
      if (!formattedDate || !dayInterval) {
        return
      }

      return new Date(`${formattedDate} ${dayInterval.min}`)
    }, [formattedDate, dayInterval])

    useEffect(() => {
      const timeIndicator = document.getElementById('time-indicator')
      if (timeIndicator) {
        timeIndicator?.scrollIntoView({ block: 'center', behavior: 'auto' })
      }
    }, [timeIndicator])

    const areEventsOverlapping = (events) => {
      const eventsByRoom = events.reduce((acc, event) => {
        if (!acc[event.resourceId]) {
          acc[event.resourceId] = []
        }
        acc[event.resourceId].push(event)
        return acc
      }, {})

      for (const room in eventsByRoom) {
        const roomEvents = eventsByRoom[room]
        roomEvents.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())

        for (let i = 0; i < roomEvents.length - 1; i++) {
          const currentEvent = roomEvents[i]
          const nextEvent = roomEvents[i + 1]

          if (new Date(currentEvent.end) > new Date(nextEvent.start)) {
            return true
          }
        }
      }

      return false
    }

    const calculateReductionPercentage = (screenWidth: number) => {
      if (screenWidth < 500) {
        return 60
      }

      if (screenWidth < 779) {
        const minReduction = 40
        const maxReduction = 60
        const range = 779 - 500
        const scale = (779 - screenWidth) / range
        return minReduction + (maxReduction - minReduction) * scale
      }

      return 0
    }

    const calculateColumnWidth = (screenWidth: number) => {
      if (screenWidth >= 779) return defaultHeaderWidth

      const reductionPercentage = calculateReductionPercentage(screenWidth)
      const reductionFactor = reductionPercentage / 100

      const overlapFactor = areEventsOverlapping(events) ? 1 : 0.7
      return defaultHeaderWidth - defaultHeaderWidth * reductionFactor * overlapFactor
    }

    useEffect(() => {
      const newWidth = calculateColumnWidth(screenWidth)
      setColumnWidth(newWidth)
    }, [screenWidth, events])

    useEffect(() => {
      const observer = new MutationObserver(() => {
        const timeIndicatorElements = document.querySelectorAll('.rbc-current-time-indicator')
        if (timeIndicatorElements.length > 0) {
          const timeIndicator = timeIndicatorElements[0] as HTMLElement
          setTimeout(() => {
            if (!timeIndicatorRef.current) {
              setTimeIndicator(timeIndicator)
            }
            if (!isRealTimeTracker) {
              const timeIndicatorElementsArray = Array.from(timeIndicatorElements) as HTMLElement[]
              if (timeIndicatorElementsArray.length > 0) {
                timeIndicatorElementsArray.forEach((element) => {
                  element.style.display = 'none'
                  setTimeIndicator(timeIndicator)
                })
              }
              observer.disconnect()
            }
          })
        }
      })
      observer.observe(document.body, { childList: true, subtree: true })

      return () => {
        observer.disconnect()
      }
    }, [timeIndicator])

    useEffect(() => {
      const interval = setInterval(() => {
        setTime(new Date())
      }, 1000)

      return () => clearInterval(interval)
    }, [])

    useEffect(() => {
      updateDragging(false)
      window.document.addEventListener('wheel', handleManageTimeInterval, { passive: false })
      return () => {
        window.document.removeEventListener('wheel', handleManageTimeInterval)
      }
    }, [])

    useEffect(() => {
      const events: any = schedules
        .filter((item: IPatientSchedule) =>
          isRealTimeTracker
            ? !STATUSES_TO_BE_HIDDEN_IN_PATIENT_ACTIVITY.includes(item?.status?.code)
            : true,
        )
        .map((item: IPatientSchedule) => {
          return {
            id: item.id,
            title:
              item?.patient?.name || `${item?.patient?.first_name} ${item?.patient?.last_name}`,
            start: toDate(new Date(`${formattedDate} ${item.start_time}`)),
            end: toDate(new Date(`${formattedDate} ${item.end_time}`)),
            resourceId: `${item?.room?.label}/${item?.room?.value}`,
            status: item.status,
            temperature_bypass: item.temperature_bypass,
            isDraggable: true,
            current_location: item.current_location,
            sms_notification: item.sms_notification,
            error_sms_message: item.error_sms_message,
            notes: item.notes,
            temperature: item.temperature,
            consultation_staff: item.consultation_staff,
            appointment_start_time: item.appointment_start_time,
            appointment_end_time: item.appointment_end_time,
            original_room: item.original_room,
            room_type_id: item.room_type_id,
            procedure: item.procedure,
          }
        })
      setEvents(events)
    }, [schedules])

    const handleUpdateTimeIndicator = useCallback((mutationRecord: MutationRecord[]) => {
      const timeWrapper = document.getElementById('time-indicator')
      if (timeWrapper) {
        timeWrapper.innerHTML = format(
          DateService.getNowToServerZone(timeZone),
          TIME_FORMAT.TIME_GUTTER_PICKER_HH_SS,
        )
        timeWrapper.style.top = `calc(${
          (mutationRecord[0].target as HTMLElement).style.top
        } - 12px)`
      }
    }, [])

    useMutationObserver(timeIndicator, handleUpdateTimeIndicator, {
      attributes: true,
      attributeFilter: ['style'],
    })

    const handleZoomIn = () => {
      setZoomLevel((level) => (level < 15 ? level + 1 : level))
    }

    const handleZoomOut = () => {
      setZoomLevel((level) => (level > 1 ? level - 1 : level))
    }

    const updateDragging = (value: boolean) => {
      dispatch(UPDATE_INFO_DRAGGING(value))
    }

    const handleManageTimeInterval = (event: any): void => {
      if (event.altKey) {
        event.preventDefault()
        if (event.deltaY > 0) handleZoomOut()
        else if (event.deltaY < 0) handleZoomIn()
      }
    }

    const isBetween = (number: number, lowerBound: number, upperBound: number): boolean => {
      return number >= lowerBound && number <= upperBound
    }

    const shouldProceedToUpdate = ({
      resourceId,
      start,
      end,
      event,
      room,
      isResizing,
    }: {
      resourceId: string
      start: any
      end: any
      event: any
      room: IPatientRoom
      isResizing?: boolean
      isDrag?: boolean
    }) => {
      console.log('>>> resourceId', resourceId)
      const diffInMinutes = differenceInMinutes(end, start)

      if (diffInMinutes < MIN_SCHEDULE_TIME_IN_MINUTES) {
        setNotification({
          title: 'Update schedule error',
          description: `You're schedule can't be less than ${MIN_SCHEDULE_TIME_IN_MINUTES} minutes.`,
          type: ALERT_CONSTANTS.ERROR,
        })

        return false
      }

      const eventsFromRoom = events.filter(
        (item) =>
          item.resourceId === resourceId &&
          item.id !== event.id &&
          COUNT_AS_OVERLAPPING.includes(item.status.code),
      )

      const { nrOfOverlappingEvents, overlappingEvents } =
        InfoService.getNumberOfOverlappingIntervals({ start, end }, eventsFromRoom, resourceId)

      if (!isBetween(nrOfOverlappingEvents, 1, room.max_patients || 1) && !room?.is_waiting_room) {
        setNotification({
          title: 'Update schedule error',
          description: `You reached the max number of ${
            room.max_patients || 1
          } patients at the same time for this room.`,
          type: ALERT_CONSTANTS.ERROR,
        })
        return false
      }

      const shouldApplyRoomRule = !InfoService.checkIfCurrentDateOverlapDisabledIntervals(
        disabledIntervals,
        room,
        dayInterval,
        timeZone,
      )
      if (
        shouldApplyRoomRule &&
        room.status &&
        room?.status?.code !== ROOM_STATUS.FREE &&
        room?.status?.code !== ROOM_STATUS.CLEANUP &&
        room?.status?.code !== ROOM_STATUS.BUSY &&
        !isResizing
      ) {
        return false
      }

      const { start: event_start, end: event_end, resourceId: eventRoom } = event
      const { overlappingEvents: overlappingEventsBeforeUpdate } =
        InfoService.getNumberOfOverlappingIntervals(
          { start: event_start, end: event_end },
          eventsFromRoom,
          eventRoom,
        )

      const shouldShowOverlappingEventsNotification =
        nrOfOverlappingEvents > 1 &&
        !UtilService.arrDeepEqual(overlappingEventsBeforeUpdate, overlappingEvents) &&
        room.value !== rooms[rooms.length - 1].value

      if (shouldShowOverlappingEventsNotification) {
        setAwaitConfirmationEvent({
          start,
          end,
          event,
          room,
        })

        return false
      }

      return true
    }

    const handleUpdatedEvents = async (adjustedData: any, event: any): Promise<boolean> => {
      const successUpdate = await updatePatientSchedule(adjustedData, event.id)
      return successUpdate
    }

    const proceedToUpdateEvents = async () => {
      const { start, end, event, room } = awaitConfirmationEvent
      const adjustedData = {
        room: room?.value,
        start_time: format(start, TIME_FORMAT.TIME_PICKER_HH_SS),
        end_time: format(end, TIME_FORMAT.TIME_PICKER_HH_SS),
        temperature_bypass: event.temperature_bypass,
        consultation_staff: event.consultation_staff,
        procedure_code: !event?.procedure?.isCustom ? event?.procedure?.code : null,
        procedure_description: event?.procedure?.description,
      }

      const copyOfEvents = [...events]

      const updated = events.map((item) =>
        item.id === event.id ? { ...item, end, start, resourceId: room.label } : item,
      )
      setEvents(updated)

      const successUpdate = await handleUpdatedEvents(adjustedData, event)

      if (!successUpdate) {
        setEvents(copyOfEvents)
      }

      setAwaitConfirmationEvent(null)
    }

    const handleResize: withDragAndDropProps['onEventResize'] = async (
      data: any,
    ): Promise<void> => {
      if (isUpdating || disabledEverything) return

      const { end, start, event, resourceId } = data
      let adjustedEnd = end
      let diffInMinutes = differenceInMinutes(adjustedEnd, start)

      if (diffInMinutes === 0) {
        adjustedEnd = addMinutes(start, 5)
        diffInMinutes = differenceInMinutes(adjustedEnd, start)
      }

      if (diffInMinutes < MIN_SCHEDULE_TIME_IN_MINUTES) {
        setNotification({
          title: 'Update schedule error',
          description: `Schedule duration can't be less than ${MIN_SCHEDULE_TIME_IN_MINUTES} minutes.`,
          type: ALERT_CONSTANTS.ERROR,
        })
        return
      }

      const [label, value] = resourceId.split('/')
      const room = rooms.find((room: IPatientRoom) => room.label === label && room.value === value)
      const shouldUpdateEvent = shouldProceedToUpdate({
        resourceId,
        start,
        end: adjustedEnd,
        event,
        room,
        isResizing: true,
      })

      if (!shouldUpdateEvent) return
      const adjustedData = {
        room: room?.value,
        start_time: format(start, TIME_FORMAT.TIME_PICKER_HH_SS),
        end_time: format(adjustedEnd, TIME_FORMAT.TIME_PICKER_HH_SS),
        temperature_bypass: event.temperature_bypass,
        consultation_staff: event.consultation_staff,
        procedure_code: !event?.procedure?.isCustom ? event?.procedure?.code : null,
        procedure_description: event?.procedure?.description,
      }

      const copyOfEvents = [...events]
      const updated = events.map((item) =>
        item.id === event.id ? { ...item, end: adjustedEnd, start } : item,
      )
      setEvents(updated)

      const successUpdate = await handleUpdatedEvents(adjustedData, event)
      if (!successUpdate) {
        setEvents(copyOfEvents)
      } else {
        dispatch(UPDATE_INFO_DRAGGING(false))
      }
    }

    const handleDrag: withDragAndDropProps['onEventDrop'] = async (props: any): Promise<void> => {
      updateDragging(false)

      if (isUpdating || disabledEverything) return

      const { event, resourceId, start, end } = props

      const diffInMinutes = differenceInMinutes(end, start)
      if (diffInMinutes < MIN_SCHEDULE_TIME_IN_MINUTES) {
        setNotification({
          title: 'Update schedule error',
          description: `Schedule duration can't be less than ${MIN_SCHEDULE_TIME_IN_MINUTES} minutes.`,
          type: ALERT_CONSTANTS.ERROR,
        })
        return
      }

      const [label, value] = resourceId.split('/')
      const room = rooms.find((room: IPatientRoom) => room.label === label && room.value === value)
      const shouldUpdateEvent = shouldProceedToUpdate({
        resourceId,
        start,
        end,
        event,
        room,
        isDrag: true,
      })

      if (!shouldUpdateEvent) return
      const adjustedData = {
        room: room.value,
        consultation_staff: event.consultation_staff,
        start_time: format(start, TIME_FORMAT.TIME_PICKER_HH_SS),
        end_time: format(end, TIME_FORMAT.TIME_PICKER_HH_SS),
        temperature_bypass: event.temperature_bypass,
        procedure_code: !event?.procedure?.isCustom ? event?.procedure?.code : null,
        procedure_description: event?.procedure?.description,
      }

      const copyOfEvents = [...events]
      const updated = events.map((item) => {
        return item.id === event.id ? { ...item, end, start, resourceId } : item
      })

      setEvents(updated)
      const successUpdate = await handleUpdatedEvents(adjustedData, event)
      if (!successUpdate) {
        setEvents(copyOfEvents)
      }
    }

    const handleSendNotifications = () => {
      sendInfoPatientsNotification()
      setIsSendSmsConfirmVisible(false)
    }

    const handleSelectSlot = (event: any) => {
      const isClick = event.action === 'click'
      const isSelectedOneSlot = event.slots.length === 2 && !allowAddSchedule
      if (disabledEverything || isSelectedOneSlot) return

      const [label, value] = event.resourceId.split('/')
      const room = rooms.find((room: IPatientRoom) => room.label === label && room.value === value)
      if (room?.add_patient === 0) return

      setAllowAddSchedule(false)
      const { start, resourceId } = event
      let { end } = event

      const diffInMinutes = differenceInMinutes(end, start)

      const prepPopulateTime = isClick ? prepopulateTimeOnClick : MIN_PRE_POPULATE_TIME
      const updatedEnd = add(start, { minutes: prepPopulateTime })
      const disabledIntervalsForRoom = disabledIntervals
        .filter((interval: any) => interval.resourceId === resourceId)
        .sort((a: any, b: any) => compareAsc(a.start, b.start))

      const firstDisabledInterval = disabledIntervalsForRoom.find(
        (interval: any) => isAfter(interval.start, start) && isAfter(updatedEnd, interval.start),
      )

      const endDayDate = new Date(`${formattedDate} ${dayInterval.max}`)

      const dateToCheck = firstDisabledInterval?.start || endDayDate

      if (diffInMinutes < prepPopulateTime && !isAfter(updatedEnd, dateToCheck)) {
        end = updatedEnd
      } else if (diffInMinutes < prepPopulateTime && isAfter(updatedEnd, dateToCheck)) {
        end = dateToCheck
      }

      if (createCustomAppointment) {
        createCustomAppointment(start, end, resourceId)
      }
    }

    const handleSelecting = (event: any) => {
      if (disabledEverything) {
        return false
      }

      if (isUpdating || DateService.getIsValidAndCurrentDay(event.start, timeZone)) {
        return false
      }

      const [label, value] = event.resourceId.split('/')
      const room = rooms.find((room: IPatientRoom) => room.label === label && room.value === value)
      if (room?.add_patient === 0) {
        return false
      }

      return true
    }

    const timeGutterWrapper = (prop: any) => {
      const { children } = prop
      const timeIndicator = document.getElementsByClassName('rbc-current-time-indicator')[0]
      let currentHour = ''

      if (Boolean(timeIndicator) && isRealTimeTracker) {
        currentHour = format(
          DateService.getNowToServerZone(timeZone),
          TIME_FORMAT.TIME_GUTTER_PICKER_HH_SS,
        )
      }
      const newElement = (
        <div
          key={currentHour}
          className={styles.time}
          style={{
            top: `calc(${(timeIndicator as HTMLElement)?.style?.top} - 12px)`,
          }}
          id='time-indicator'>
          {currentHour}
        </div>
      )

      if (isRealTimeTracker && currentHour) {
        children.props.children.push(newElement)
      }
      return children
    }

    const getIsEventDraggable = (event: any) => {
      const isSelectedDateFromPast = DateService.isPast(
        new Date(`${formattedDate} 23:59`),
        timeZone,
      )

      const isInProgress =
        DateService.isPast(event.start, timeZone) && !DateService.isPast(event.end, timeZone)

      const isEventFromPast =
        isSelectedDateFromPast ||
        (DateService.isPast(event.start, timeZone) &&
          DateService.isPast(event.end, timeZone) &&
          !ALLOW_RESCHEDULE_CURRENT_DAY_PAST_EVENTS.includes(event.status.code))

      const isAllowedToDrag = InfoService.checkIfActionShouldBeVisible(
        ACTION_TYPES.RESCHEDULE,
        event.status.code,
      )

      return (
        event.isDraggable && !isUpdating && (isInProgress || !isEventFromPast) && isAllowedToDrag
      )
    }

    const handleAllowScheduleForSmallInterval = () => {
      timerRef.current = setTimeout(() => {
        setAllowAddSchedule(true)
      }, MIN_LONG_PRESS_TO_ADD_SCHEDULE_MS)
    }

    const handleCancelAllowScheduleForSmallInterval = () => {
      clearTimeout(timerRef.current)
    }

    const adjustHeaderWidth = (
      header: HTMLElement,
      isWaitingRoom: boolean,
      allRoomsAreShown: boolean,
    ) => {
      const pixelRatio = window.devicePixelRatio === 1 ? 0 : window.devicePixelRatio
      const otherSpace = 150 - pixelRatio * 10
      let width: string

      if (rooms.length === 1) {
        width = `calc(100vw - ${otherSpace}px)`
      } else if (isWaitingRoom && allRoomsAreShown) {
        const totalRoomWidth = rooms.length * columnWidth

        if (rooms.length === 2 && !isPromotion) {
          width = `calc(100vw - 15px - ${totalRoomWidth - otherSpace}px)`
        } else {
          width = `calc(100vw - 20px - ${totalRoomWidth - otherSpace}px)`
        }
      } else {
        width = `${columnWidth}px`
      }

      header.style.width = width
    }

    const allRoomsAreShown = useMemo(() => {
      const sidebarWidth = screenWidth > 576 ? 100 : 0
      const padding = screenWidth > 576 ? 64 : 32
      const zoomControlsWidth = 45
      const roomsWidth = screenWidth - sidebarWidth - padding - zoomControlsWidth

      return rooms.length * columnWidth < roomsWidth
    }, [screenWidth, rooms])

    const adjustColumnWidth = (column: HTMLElement, index: number) => {
      const isWaitingRoom = rooms[index]?.is_waiting_room
      const width =
        rooms.length === 1
          ? 'calc(100% - 62px)'
          : isWaitingRoom && allRoomsAreShown
          ? `calc(100% - ${(rooms.length - 1) * columnWidth + 72}px)`
          : `${columnWidth}px`

      column.style.minWidth = width
      column.style.width = width
    }

    const minimapBackground = useMemo(() => {
      return (
        <>
          {rooms.map((_, index) => (
            <div
              key={index}
              className={styles.minimapRoom}
              style={{ width: `${100 / rooms.length}%` }}
            />
          ))}
        </>
      )
    }, [rooms])

    useLayoutEffect(() => {
      if (calendarRef.current && rooms.length > 0 && !isFetching) {
        const columns = calendarRef.current.querySelectorAll('.rbc-day-slot')
        columns.forEach(adjustColumnWidth)

        const headers = calendarRef.current.querySelectorAll('.rbc-row-resource')
        headers.forEach((header: HTMLElement, index: number) => {
          const isWaitingRoom = rooms[index]?.is_waiting_room
          adjustHeaderWidth(header, isWaitingRoom, allRoomsAreShown)
        })
      }
    }, [calendarRef, rooms, screenWidth, zoomControlsWidth, location])

    const getZoomStyle = (): React.CSSProperties => {
      const baseHeight = 30
      const zoomMultiplier = Math.max(1, zoomLevel / 3)
      const height = Math.round(baseHeight * zoomMultiplier)

      return {
        '--zoom-factor': `${height}px`,
      } as React.CSSProperties
    }

    useEffect(() => {
      if (calendarRef.current) {
        const container = calendarRef.current.querySelector('.rbc-time-view')
        const content = calendarRef.current.querySelector('.rbc-time-content')
        if (container && content) {
          setContainerWidth(container.clientWidth)
          setContentWidth(content.scrollWidth)
        }
      }
    }, [calendarRef, rooms, screenWidth])

    const handleMinimapScroll = (scrollLeft: number) => {
      if (calendarRef.current) {
        const content = calendarRef.current.querySelector('.rbc-time-content')
        if (content) {
          content.scrollLeft = scrollLeft
        }
      }
    }

    useEffect(() => {
      const content = calendarRef.current.querySelector('.rbc-time-content')

      const setCalendarScroll = () => {
        setContentScroll(content.scrollLeft)
      }

      content.addEventListener('scroll', setCalendarScroll)
      return () => window.removeEventListener('scroll', setCalendarScroll)
    }, [])

    return (
      <>
        {message}
        <div
          ref={calendarRef}
          style={getZoomStyle()}
          onMouseDown={handleAllowScheduleForSmallInterval}
          onTouchStart={handleAllowScheduleForSmallInterval}
          onMouseUp={handleCancelAllowScheduleForSmallInterval}
          onTouchEnd={handleCancelAllowScheduleForSmallInterval}
          className={classNames(styles.parentCalendar, {
            [styles.parentCalendarDisabled]: disabledEverything,
          })}>
          {isUpdating && <SpinnerOverlay />}
          {!isFetching && subtitle}
          <DndCalendar
            longPressThreshold={10}
            selectable={!isMobileOrTablet}
            defaultDate={defaultDate}
            timeslots={1}
            toolbar={null}
            events={[...events, ...disabledIntervals]}
            defaultView={defaultView}
            components={{
              toolbar: null,
              event: calendarEvent,
              timeGutterHeader: () => (
                <ZoomControls
                  onZoomIn={handleZoomIn}
                  onZoomOut={handleZoomOut}
                  setZoomControlsWidth={setZoomControlsWidth}
                />
              ),
              timeGutterWrapper: timeGutterWrapper,
            }}
            step={15}
            resources={roomsHeader}
            onEventDrop={handleDrag}
            onSelectEvent={handleDisplayEventDetails}
            showMultiDayTimes={false}
            onEventResize={handleResize}
            localizer={localizer}
            getNow={() => DateService.getNowToServerZone(timeZone, time)}
            formats={{
              timeGutterFormat: 'h:mm a',
              eventTimeRangeFormat: () => {
                return null
              },
            }}
            eventPropGetter={(event: any) => ({
              style: InfoService.getScheduleColor(event.status.code),
              className: classNames({
                'rbc-disabled-event': event.status.code === PATIENT_STATUSES.DISABLED,
                'rbc-event-over-temp': event.status.code === PATIENT_STATUSES.CHECKED_IN_ON_SITE,
                'rbc-fixed-event': !event.isDraggable,
              }),
            })}
            onDragStart={() => updateDragging(true)}
            resourceIdAccessor={({ resourceId }: any) => resourceId}
            resourceTitleAccessor={({ resourceTemplate }: any) => resourceTemplate}
            min={new Date(`${formattedDate} ${dayInterval.min}`)}
            max={new Date(`${formattedDate} ${dayInterval.max}`)}
            dayLayoutAlgorithm='no-overlap'
            draggableAccessor={getIsEventDraggable}
            onSelectSlot={handleSelectSlot}
            onSelecting={handleSelecting}
            tooltipAccessor={null}
          />

          <Show
            when={
              !DateService.isPast(new Date(`${formattedDate} 23:59`), timeZone) &&
              eventsToSendNotification.length > 0 &&
              !isRealTimeTracker
            }>
            <div className={styles.parentAction}>
              <Button
                modifier={BUTTON_MODIFIER.QUATERNARY}
                onClick={() => setIsSendSmsConfirmVisible(true)}
                loading={isSendingNotifications}>
                <span className='center' style={{ gap: 8 }}>
                  {!isSendingNotifications && (
                    <img
                      className={styles.parentActionIcon}
                      src={sendMessageIcon}
                      alt={ALT_CONSTANTS.ACTION_ICON}
                    />
                  )}
                  <span>Notify patients</span>
                </span>
              </Button>
            </div>
          </Show>
        </div>

        {screenWidth > 1024 && (
          <Minimap
            onScroll={handleMinimapScroll}
            containerWidth={containerWidth}
            contentWidth={contentWidth}
            contentScroll={contentScroll}
            backgroundElement={minimapBackground}
          />
        )}

        <Warning
          open={Boolean(awaitConfirmationEvent)}
          title={INFO_CONSTANTS.ARE_YOU_SURE}
          warning={INFO_CONSTANTS.MULTIPLE_PATIENTS_TITLE}
          okButtonText={INFO_CONSTANTS.YES}
          handleClose={() => setAwaitConfirmationEvent(null)}
          handleConfirm={proceedToUpdateEvents}
          hasCancel
        />

        <Warning
          open={showBusyNotification}
          title={INFO_CONSTANTS.INFO}
          warning={INFO_CONSTANTS.BUSY_ROOM_WARNING}
          handleClose={() => setShowBusyNotification(false)}
          okButtonText={BUTTON_CONSTANTS.CLOSE}
          handleConfirm={() => setShowBusyNotification(false)}
        />

        <SendSMSConfirmation
          open={isSendSmsConfirmVisible}
          handleConfirm={handleSendNotifications}
          handleClose={() => setIsSendSmsConfirmVisible(false)}
        />
      </>
    )
  },
)
