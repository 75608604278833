import { useAuth, useNotification } from 'app/providers'

import { ALERT_CONSTANTS } from 'common/constants/alertConstants'
import { HTTP_RESPONSE_STATUS } from 'common/constants/httpMethodsConstants'
import { RESPONSE_PROPERTY_CONSTANTS } from 'common/constants/reponsePropertyConstants'

export type ApiResponse = {
  data?: any
  error?:
    | {
        status: number
        data: string[] | string
      }
    | unknown
}

interface IProcessResponseProps {
  error?: string
  success?: string
  title?: string
  errorCallback?: () => void
  successCallback?: () => void
  description?: string | JSX.Element
  displayError?: boolean
}

export const useApiResponse = () => {
  const { setNotification } = useNotification()
  const { setUser, user } = useAuth()

  const processApiResponse = (
    response: ApiResponse,
    {
      title,
      success,
      successCallback,
      error,
      errorCallback,
      description,
      displayError = true,
    }: IProcessResponseProps,
  ): void => {
    if (Object.hasOwn(response, RESPONSE_PROPERTY_CONSTANTS.SUCCESS)) {
      if (success)
        setNotification({
          title: success,
          description: description ?? response?.data.message,
          type: ALERT_CONSTANTS.SUCCESS,
        })
      if (successCallback) successCallback()
    } else {
      if (error) {
        const errorStatus = (response.error as { status?: number })?.status
        if (
          errorStatus === HTTP_RESPONSE_STATUS.UNAUTHORIZED ||
          errorStatus === HTTP_RESPONSE_STATUS.CONNECTION_TIMED_OUT
        ) {
          if (user?.old_access_token) {
            const newUser = {
              ...user,
              access_token: user.old_access_token,
              is_super_doctor: true,
            }
            delete newUser.old_access_token
            setUser(newUser)
          } else {
            setUser(null)
          }
        }
      }
      if (displayError) {
        setNotification({
          title: title || 'Error',
          description: (response.error as { data?: string })?.data || error,
          type: ALERT_CONSTANTS.ERROR,
        })
      }
      if (errorCallback) errorCallback()
    }
  }

  return { processApiResponse }
}
