import { useAppSelector } from 'common/hooks/redux'
import { type IBookingInfoRoom } from 'features/Booking/interfaces/ILeaseInfoRoom'
import { useCartManager } from 'features/Cart'
import { useMemo } from 'react'
import _ from 'lodash'

const hasSelectedSlots = (room: IBookingInfoRoom): boolean => {
  return room?.selectedDays?.some((day) => day?.selectedSlots?.length > 0) ?? false
}

const isValidRoom = (room: IBookingInfoRoom): boolean => {
  return !!room?.room?.id
}

export const useCartHasChanges = () => {
  const { newLeaseInfo: newRoom, newLeaseRooms } = useAppSelector((state) => state.leaseReducer)
  const { bookingCart } = useCartManager()

  const { rooms: reservedRooms = [] } = bookingCart?.data ?? {}

  const oldRoom: IBookingInfoRoom = useMemo((): IBookingInfoRoom => {
    return (
      reservedRooms.find(
        ({ room, id: cartId }: IBookingInfoRoom): boolean =>
          room.id === newRoom?.room?.id && cartId === newRoom?.id,
      ) ?? {}
      // can be same room id but different cart id (Exam Room A, Exam Room B)
    )
  }, [reservedRooms, newRoom])

  const _oldRooms = performRooms(reservedRooms)
  const _newRooms = performRooms(newLeaseRooms)

  const _oldRoom = performRooms([oldRoom])
  const _newRoom = performRooms([newRoom])

  const isRoomsEqual = _.isEqual(_oldRooms, _newRooms)
  const isRoomEqual = _.isEqual(_oldRoom, _newRoom)

  const isOldRoomValid = isValidRoom(oldRoom)

  const hasRoomChanges =
    isOldRoomValid && !newRoom?.room?.isNewRoom ? !isRoomEqual : hasSelectedSlots(newRoom)

  const isDeepEqual = isRoomsEqual && !hasRoomChanges

  console.log({ newRoom })

  return !isDeepEqual
}

const performRooms = (rooms: IBookingInfoRoom[]) => {
  return rooms
    ?.map((room) => {
      return {
        id: room.id,
        slots: room?.selectedDays
          ?.map((day) => day.selectedSlots?.map((slot) => slot.id).sort((a, b) => a - b))
          ?.flatMap((slotIds) => slotIds),
      }
    })
    ?.sort((a, b) => a.id - b.id)
}
