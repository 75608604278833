import {
  ACTION_MESSAGE,
  ACTION_TYPES,
  ALLOW_RESCHEDULE_CURRENT_DAY_PAST_EVENTS,
  APPOINTMENT_STATUS_ALLOWED_ACTIONS,
  PATIENT_STATUSES,
} from 'features/Home/constants/infoConstants'
import dragIcon from 'features/Home/assets/drag.svg'
import resizeIcon from 'features/Home/assets/resize.svg'
import deleteIcon from 'features/Home/assets/trash.svg'
import cancelIcon from 'features/Home/assets/cancel.svg'
import checkIcon from 'features/Home/assets/check.svg'
import userIcon from 'features/Home/assets/user.svg'
import checkoutIcon from 'features/Home/assets/checkout.svg'
import styles from './eventTooltip.module.scss'
import { Show } from 'common/components/Show/Show'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { DateService } from 'common/services/dateService'

type EventTooltipProps = {
  event: any
}

const EventTooltip: FC<EventTooltipProps> = ({ event }) => {
  const { status } = event
  const timeZone = 'Bucharest'

  const disabledEverything = useMemo(() => {
    return (
      DateService.isPast(event.start, timeZone) &&
      event.status.code !== PATIENT_STATUSES.CHECKED_IN_ON_SITE
    )
  }, [event.start, event.status.code])

  const actionIcon = useCallback((action: ACTION_TYPES) => {
    switch (action) {
      case ACTION_TYPES.RESCHEDULE:
        return dragIcon

      case ACTION_TYPES.RESIZE_SCHEDULE:
        return resizeIcon

      case ACTION_TYPES.DELETE:
        return deleteIcon

      case ACTION_TYPES.CANCEL:
        return cancelIcon

      case ACTION_TYPES.EDIT_TEMPERATURE:
        return checkIcon

      case ACTION_TYPES.EDIT_CONSULTATION_STAFF:
        return userIcon

      case ACTION_TYPES.CHECK_OUT:
        return checkoutIcon

      default:
        return ''
    }
  }, [])

  return (
    <div className={styles.tooltip}>
      <Show
        when={
          (disabledEverything && !ALLOW_RESCHEDULE_CURRENT_DAY_PAST_EVENTS.includes(status.code)) ||
          APPOINTMENT_STATUS_ALLOWED_ACTIONS[status.code as PATIENT_STATUSES].length === 0
        }>
        <span>You can't do any action for this appointment.</span>
      </Show>
      <Show
        when={
          !(
            disabledEverything && !ALLOW_RESCHEDULE_CURRENT_DAY_PAST_EVENTS.includes(status.code)
          ) && APPOINTMENT_STATUS_ALLOWED_ACTIONS[status.code as PATIENT_STATUSES].length > 0
        }>
        <p className={styles.tooltipTitle}>
          For this appointment you can do the following actions:
        </p>

        {APPOINTMENT_STATUS_ALLOWED_ACTIONS[status.code as PATIENT_STATUSES].map((action) => (
          <div className={styles.tooltipActionItem} key={action}>
            <img
              className={styles.tooltipIcon}
              src={actionIcon(action)}
              alt={ALT_CONSTANTS.ACTION_ICON}
            />
            <span>{ACTION_MESSAGE[action]}</span>
          </div>
        ))}
      </Show>
    </div>
  )
}

export default EventTooltip
