import './scheduleCalendar.scss'
import * as React from 'react'
import { memo, useCallback, useMemo, useState } from 'react'
import {
  Day,
  DragAndDrop,
  Inject,
  Resize,
  ResourceDirective,
  ResourcesDirective,
  ScheduleComponent,
  ViewDirective,
  ViewsDirective,
} from '@syncfusion/ej2-react-schedule'

import { extend } from '@syncfusion/ej2-base'
import type { ScheduleCalendarProps } from './scheduleCalendar.types'
import type { ObjectType } from 'common/interfaces/object'
import { ZoomControls } from 'features/Home/Book/Booking/Schedule/PatientSchedule/Calendar/ZoomControls/ZoomControls'
import { InfoService } from 'features/Home/services/infoService'
import CalendarEvent from './CalendarEvent'
import EventTooltip from './EventTooltip'
import { useAppSelector } from 'common/hooks/redux'
import { useScheduleCalendar } from './useScheduleCalendar'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import type { IPatientRoom } from 'features/Home/interfaces/IInfoPatient'
import { add, compareAsc, differenceInMinutes, isAfter } from 'date-fns'
import { MIN_PRE_POPULATE_TIME } from 'features/Home/constants/infoConstants'

export const ScheduleCalendar = memo(
  ({
    createCustomAppointment,
    disabledEverything,
    prepopulateTimeOnClick = MIN_PRE_POPULATE_TIME,
    resources = [],
    setEditedPatient,
  }: ScheduleCalendarProps) => {
    const [interval, setInterval] = useState(15)

    const { day } = useParams()
    const formattedDate = dayjs(day).format('YYYY-MM-DD')

    const {
      dayInterval,
      disabledIntervals,
      timeZone,
      rooms,
      isUpdating,
      schedules = [],
    } = useAppSelector((state) => state.bookReducer)

    const { events, handleDrag, handleResize } = useScheduleCalendar({
      schedules,
      dayInterval,
      disabledIntervals,
      formattedDate,
      timeZone,
      rooms,
      isUpdating,
    })

    const data = useMemo(() => extend([], events, null, true), [events])

    const resourceHeaderTemplate = useCallback((props: ObjectType) => {
      return props.resourceData.resourceTemplate
    }, [])

    const handleZoomIn = useCallback(() => {
      setInterval((level) => (level < 15 ? level + 1 : level))
    }, [])

    const handleZoomOut = useCallback(() => {
      setInterval((level) => (level > 1 ? level - 1 : level))
    }, [])

    const indentTemplate = useCallback(() => {
      return <ZoomControls onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} />
    }, [handleZoomIn, handleZoomOut])

    const eventRendered = useCallback((props: any) => {
      props.element.style.color = InfoService.getScheduleColor(props.data.status.code)?.color
      props.element.style.background = InfoService.getScheduleColor(
        props.data.status.code,
      )?.backgroundColor
      props.element.style.border = `1px solid ${
        InfoService.getScheduleColor(props.data.status.code)?.backgroundColor
      }`
    }, [])

    const eventTemplate = useCallback((props: any) => {
      return <CalendarEvent event={props} />
    }, [])

    const eventTooltip = useCallback((props: any) => {
      return <EventTooltip event={props} />
    }, [])

    const handleEventClick = useCallback((props: any) => {
      props.originalEvent.preventDefault()
      props.originalEvent.stopPropagation()
      setEditedPatient(props.event)
    }, [])

    const handleChange = useCallback(async (props: any) => {
      if (props.requestType !== 'eventChange') {
        return
      }

      if (!props.data.room_id.includes(props.data.room.code)) {
        await handleDrag(props.data)
      } else {
        await handleResize(props.data)
      }
    }, [])

    const handleSelect = useCallback((props: any) => {
      if (disabledEverything || !props.data) {
        return
      }

      const [label, value] = props.data.room_id.split('/')
      const room = rooms.find((room: IPatientRoom) => room.label === label && room.value === value)

      if (room?.add_patient === 0) {
        return
      }

      const { start_time, room_id } = props.data
      let { end_time } = props.data

      const diffInMinutes = differenceInMinutes(end_time, start_time)
      const prepPopulateTime = diffInMinutes < 15 ? prepopulateTimeOnClick : MIN_PRE_POPULATE_TIME
      const updatedEnd = add(start_time, { minutes: prepPopulateTime })
      const disabledIntervalsForRoom = disabledIntervals
        .filter((interval: any) => interval.resourceId === room_id)
        .sort((a: any, b: any) => compareAsc(a.start, b.start))

      const firstDisabledInterval = disabledIntervalsForRoom.find(
        (interval: any) =>
          isAfter(interval.start, start_time) && isAfter(updatedEnd, interval.start),
      )

      const endDayDate = new Date(`${formattedDate} ${dayInterval.max}`)

      const dateToCheck = firstDisabledInterval?.start || endDayDate

      if (diffInMinutes < prepPopulateTime && !isAfter(updatedEnd, dateToCheck)) {
        end_time = updatedEnd
      } else if (diffInMinutes < prepPopulateTime && isAfter(updatedEnd, dateToCheck)) {
        end_time = dateToCheck
      }

      if (createCustomAppointment) {
        createCustomAppointment(start_time, end_time, room_id)
      }
    }, [])

    return (
      <div className='schedule-control-section'>
        <div className='col-lg-12 control-section'>
          <div className='control-wrapper'>
            <ScheduleComponent
              cssClass='room-schedule'
              width='100%'
              height='calc(100vh - 300px)'
              timeScale={{ interval, slotCount: 1 }}
              selectedDate={new Date()}
              startHour={dayInterval.min as string}
              endHour={dayInterval.max as string}
              currentView='Day'
              headerIndentTemplate={indentTemplate}
              resourceHeaderTemplate={resourceHeaderTemplate}
              eventRendered={eventRendered}
              showQuickInfo={false}
              eventClick={handleEventClick}
              actionBegin={handleChange}
              showHeaderBar={false}
              select={handleSelect}
              eventSettings={{
                dataSource: data,
                enableTooltip: true,
                enableIndicator: true,
                tooltipTemplate: eventTooltip,
                fields: {
                  startTime: { title: 'From', name: 'start_time' },
                  endTime: { title: 'To', name: 'end_time' },
                },
              }}
              group={{ resources: ['Rooms'] }}>
              <ResourcesDirective>
                <ResourceDirective
                  field='room_id'
                  title='Rooms'
                  name='Rooms'
                  dataSource={resources}
                  idField='resourceId'
                />
              </ResourcesDirective>
              <ViewsDirective>
                <ViewDirective option='Day' eventTemplate={eventTemplate} />
              </ViewsDirective>
              <Inject services={[Day, Resize, DragAndDrop]} />
            </ScheduleComponent>
          </div>
        </div>
      </div>
    )
  },
)
